import { template as template_ec4c703a120c451b952fd40287bd3c97 } from "@ember/template-compiler";
const FKLabel = template_ec4c703a120c451b952fd40287bd3c97(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
