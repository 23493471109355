import { template as template_b648aa3490ba44a3bb2fd8be39847301 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b648aa3490ba44a3bb2fd8be39847301(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
