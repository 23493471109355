import { template as template_c870b5c6e22d4eafadaf7f3919292ca4 } from "@ember/template-compiler";
const WelcomeHeader = template_c870b5c6e22d4eafadaf7f3919292ca4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
